import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AnchorLink from "../components/anchorLink"

import "./blog-post.scss"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const coverImage = getImage(post.frontmatter.coverImage)
  const { previous, next } = data

  return (
    <Layout location={location}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <main>
        <div className="relative px-4 sm:px-6 md:px-8 max-w-screen-xl mx-auto">
          <article className="blog-post">
            <header className="py-16">
              <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl sm:text-6xl lg:text-7xl leading-tight tracking-tight text-gray-900 mb-8 sm:mb-10">
                  {post.frontmatter.title}
                </h1>
                <p>{post.frontmatter.date}</p>
                {coverImage ? (
                  <div className="aspect-h-4 aspect-w-3 rounded-lg mt-10">
                    <GatsbyImage
                      image={coverImage}
                      alt=""
                      className="w-full rounded-lg object-cover object-center transition"
                    />
                  </div>
                ) : null}
              </div>
            </header>
            <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              className="mx-auto prose md:prose-lg"
            />
            <footer>{/* <Bio /> */}</footer>
          </article>
          <nav className="blog-post-nav px-10 py-6">
          <hr class="my-8" />
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <AnchorLink to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </AnchorLink>
                )}
              </li>
              <li>
                {next && (
                  <AnchorLink to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </AnchorLink>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </main>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        coverImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
